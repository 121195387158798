import { merge } from 'lodash';
import uatConfig from './uat';
import type { Config } from './default';

// Default/fallback-values for local environment is UAT
const config: Config = merge({}, uatConfig, {
  publicUrl: 'http://tvweb.local:4000',
  sso: {
    clientId: '9now-web',
  },
  tracking: {
    snowplow: {
      trackingUrl: 'https://t-dev.nineanalytics.io/',
    },
    nuidCookieName: 'int-NUID',
  },
  livePlayer: {
    accountId: '4468173393001',
    playerId: 'Ipp0IsNf0',
  },
  webDedicatedPlayer: {
    accountId: '4468173393001',
    playerId: 'aWTGYx1FZ',
  },
  user: {
    baseUrl: 'https://uat.user-api.9now.com.au/v1',
  },
});

export default config;
