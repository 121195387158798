'use client';

import theme from 'src/styles/theme';
import StyledComponentsRegistry from 'src/app/lib/registry';
import { ThemeProvider } from 'styled-components';

export function ThemeWrapper({ children }: { children: React.ReactNode }) {
  return (
    <StyledComponentsRegistry>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledComponentsRegistry>
  );
}
