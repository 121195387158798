import * as mi9tracking from '@nec-network/mi9-tracking-loader';

let trackingLibPromise: Promise<mi9tracking.Tracking>;

export function getTrackingLib(env: string) {
  if (!trackingLibPromise) {
    trackingLibPromise = mi9tracking.load(env);
  }
  return trackingLibPromise;
}
