import { Loader } from '@googlemaps/js-api-loader';
import { isSameMonth } from 'date-fns';

type GeolocationDataType = {
  status?: string;
  latitude?: number;
  longitude?: number;
  state?: string;
  postcode?: string;
  location?: string;
  isLoggedIn?: boolean;
  date?: string;
};

const HELP_PAGE_LOCATION_ARTICLE =
  'https://help.9now.com.au/hc/en-au/articles/34083985095705-Why-does-9Now-need-access-to-my-location';
const apiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? '';
const stateAbbreviations: string[] = ['NSW', 'QLD', 'WA', 'SA', 'TAS', 'NT', 'ACT', 'VIC'];

export const getGeolocationData = () => JSON.parse(localStorage.getItem('nine_geolocation') ?? '{}');

export const setGeolocationData = (data: GeolocationDataType) => {
  const currentGeolocationData = getGeolocationData();
  localStorage.setItem('nine_geolocation', JSON.stringify({ ...currentGeolocationData, ...data }));
};

const findStateFromAddressObject = (obj: google.maps.GeocoderResponse) => {
  const addressObject = obj.results[0];
  const addressComponentsArray = addressObject.address_components;

  const postcode: string =
    addressComponentsArray.find((array) => array.types?.includes('postal_code'))?.short_name ?? '';

  const shortNames: string[] = addressComponentsArray.map((address) => address.short_name);
  // If the state is not found - it'll default to NSW
  const state: string = shortNames.find((stateString) => stateAbbreviations.includes(stateString)) || 'NSW';

  return { state, postcode };
};

const reverseGeocoder = async (lat: number, lng: number) => {
  const loader = new Loader({ apiKey });
  const geocoderLibrary = await loader.importLibrary('geocoding');
  const geocoder = new geocoderLibrary.Geocoder();
  const addressObject = await geocoder.geocode({ location: { lat, lng } });
  return findStateFromAddressObject(addressObject);
};

const getPosition = (options?: PositionOptions): Promise<GeolocationPosition> =>
  new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error('Geolocation is not available, no location service.'));
    }
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });

export const getUserLocation = async (expiryOverride = false) => {
  const geolocationData = getGeolocationData();
  const isGeolocationExpired =
    !geolocationData.date ||
    (!!geolocationData.date && !isSameMonth(new Date(), new Date(geolocationData.date)));

  if (expiryOverride || isGeolocationExpired) {
    try {
      const position = await getPosition();
      const { latitude, longitude } = position.coords;
      const { state, postcode } = await reverseGeocoder(latitude, longitude);
      setGeolocationData({
        status: 'allowed',
        latitude,
        longitude,
        date: new Date().toISOString(),
        state,
        postcode,
      });
    } catch (error) {
      setGeolocationData({
        status: 'denied',
        latitude: undefined,
        longitude: undefined,
        date: new Date().toISOString(),
      });

      if (expiryOverride) {
        window.open(HELP_PAGE_LOCATION_ARTICLE);
      }

      // The following line is commented out to disable the geolocation denied notification while we rethink the logic
      // around its appearance and the user experience. If in the future we implement this banner elsewhere, remove these comments.
      // notifyGeolocationDenied();
    }
  }
};

export const formatCaseOfLocationString = (words: string): string => {
  // If the location exists in the state array - it'll return the location in uppercase
  // Otherwise - the location will be returned in sentence case
  const upperCaseWords = words.toUpperCase();
  return stateAbbreviations.includes(upperCaseWords)
    ? upperCaseWords
    : words.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const handleGeolocationLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
  event.preventDefault();
  const expiryOverride = true;
  if (expiryOverride) {
    window.open(HELP_PAGE_LOCATION_ARTICLE);
  }
  getUserLocation(expiryOverride);
};

export const handleGeolocationLinkKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
  if (event.key === 'Enter')
    handleGeolocationLinkClick(event as unknown as React.MouseEvent<HTMLAnchorElement>);
};
