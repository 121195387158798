import { Component, ReactNode, ErrorInfo } from 'react';
import Custom500 from './500';

type Props = {
  children?: ReactNode;
};

type State = {
  hasError: boolean;
};

// You can only implement ErrorBoundaries using a class component.
export default class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (window.newrelic) {
      window.newrelic.noticeError(error, errorInfo);
    }
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return <Custom500 />;
    }
    const { children } = this.props;

    return children;
  }
}
