import React from 'react';
import ReactDom from 'react-dom';
import Constants from './constants';

/**
 * This function is used to initialize the axe-core library for accessibility testing.
 * It is used everywhere except for beta and production environments.
 * @param {string} environment The environment where the app is running.
 */
export const initializeAxe = (environment = '') => {
  const isDev = process?.env?.NODE_ENV === 'development';
  const isProd = ['beta', 'prod'].includes(environment);
  if (typeof window !== 'undefined' && (isDev || !isProd) && Constants.IS_TEST_ENV === false) {
    /** Needs a dynamic import to prevent the code running in the server, where it will complain that the window is not defined and fail. */
    import('@axe-core/react').then((axe) => axe.default(React, ReactDom, 2000));
  }
};
