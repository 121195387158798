import { merge } from 'lodash';
import defaultConfig from './default';
import type { Config } from './default';

const config: Config = merge({}, defaultConfig, {
  publicUrl: 'https://www.9now.com.au',
  apiUrl: 'https://api.9now.com.au',
  tvApiUrl: 'https://tv-api.9now.com.au/v2',
  sso: {
    authUrl: 'https://login.nine.com.au',
    clientId: '9now-web',
    redirectUrl: 'https://www.9now.com.au/callback',
    settingsUrl: 'https://settings.nine.com.au',
    botAuthorization: process.env.BOT_AUTHORIZATION_PROD,
  },
  features: {
    cdnTokenisation: true,
  },
  tracking: {
    snowplow: {
      trackingUrl: 'https://t.nineanalytics.io/',
    },
    nuidCookieName: 'NUID',
  },
  livePlayer: {
    accountId: '4460760524001',
    playerId: 'e0XxaWglKW',
  },
  mi9tracking: {
    omniture: {
      suiteId: 'msnportalaucatglobal',
    },
    oztam: {
      url: '//deliver.oztam.com.au/api/meter',
    },
    nielsenDigitalContentRatings: {
      enabled: true,
      apn: '9Now TV Web',
      sfcode: 'dcr', // "dcr" = production, "dcr-cert" = testing
      apid: 'PA1305FC6-10FC-4C8E-B0F6-699DCFC471F2', // "P" prefix = production, "T" prefix = testing
      section: '9now - Brand only',
      nsdkv: '511',
    },
    // This config is also present in the mi9-brightcove-players project for the tv-web-dash-player
    nielsenPluginOptions: {
      apn: '9Now TV Web',
      sfcode: 'dcr',
      apid: 'PA1305FC6-10FC-4C8E-B0F6-699DCFC471F2',
      nsdkv: '511',
    },
    syncNuid: {
      hostDomain: 'adc.9now.com.au',
    },
    environment: {
      name: 'prod',
      adcUrl: 'adc.9now.com.au',
    },
  },
  webDedicatedPlayer: {
    accountId: '4460760524001',
    playerId: 'AQkgDJ7lH',
  },
  user: {
    baseUrl: 'https://user-api.9now.com.au/v1',
  },
});

export default config;
