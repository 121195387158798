import uat from './uat';
import beta from './beta';
import stag from './stag';
import prod from './prod';
import local from './local';
import type { Config } from './default';

const validConfigEnv = ['local', 'uat', 'beta', 'stag', 'prod'] as const;

type ConfigEnv = (typeof validConfigEnv)[number];

const configs: Record<ConfigEnv, Config> = {
  uat,
  beta,
  stag,
  prod,
  local,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isValidConfigEnv = (appEnv: any): appEnv is ConfigEnv => validConfigEnv.includes(appEnv);

const getEnvironment = (): ConfigEnv => {
  const appEnv = process.env.APP_ENV;

  if (!appEnv) {
    return 'stag'; // Default environment is staging
  }

  if (!isValidConfigEnv(appEnv)) {
    throw new Error(
      `Incorrect app environment used "${appEnv}", using local instead. Supported values: ${validConfigEnv.join(
        ', '
      )}`
    );
  }

  return appEnv;
};

const env = getEnvironment();
export default { ...configs[env], env };
