/**
 * @module NotificationsUtil - This module contains utility functions for displaying notifications to the user.
 * It's a wrapper around the `react-toastify` npm module: https://www.npmjs.com/package/react-toastify.
 * It provides a simple API for displaying different types of notifications with our own defaults.
 */
import { isUnsupported, shouldNotifyWidevineUpdateForChromiumBrowsers } from 'common/browser';
import { toast, ToastOptions } from 'react-toastify';

export type NotificationType = ToastOptions<unknown>;

export const defaultNotificationConfig: NotificationType = {
  draggable: false,
  autoClose: false,
  theme: 'colored',
};

let instanceCount = 0;
export function notify(content: string | JSX.Element, config: NotificationType = {}) {
  const notificationConfig: NotificationType = { ...defaultNotificationConfig, ...config };
  instanceCount++;
  /**
   * The notification must have an id, otherwise it could be rendered multiple times due to React's strict mode or other reasons.
   * If one is not provided, one will be computed.
   */
  if (!notificationConfig.toastId) {
    notificationConfig.toastId = `notification-${instanceCount}`;
  }
  toast(content, notificationConfig);
}

export function notifyError(content: string | JSX.Element, config: NotificationType = {}) {
  notify(content, { ...config, type: 'error' });
}

export function notifySuccess(content: string | JSX.Element, config: NotificationType = {}) {
  notify(content, { ...config, type: 'success' });
}

export function notifyWarning(content: string | JSX.Element, config: NotificationType = {}) {
  notify(content, { ...config, type: 'warning' });
}

export function notifyInfo(content: string | JSX.Element, config: NotificationType = {}) {
  notify(content, { ...config, type: 'info' });
}

/**
 * Upon bootstrapping the application there are several notifications that should be displayed to the user.
 */
export function initializeAppNotifications() {
  isUnsupported() && notifyUnsupportedBrowser();
  shouldNotifyWidevineUpdateForChromiumBrowsers() && notifyWidevineUpdate();
}

export function notifyWidevineUpdate() {
  notifyError(
    <>
      <span>
        We've detected that your browser version may not be able to play some of our video content. We
        recommend that you update your browser to the latest version as soon as possible.
      </span>{' '}
      <a
        rel="noopener noreferrer"
        href="https://9now.zendesk.com/hc/en-us/articles/5798236481935-Why-isn-t-my-browser-allowing-me-to-play-video-"
        target="_blank"
      >
        Find out more.
      </a>
    </>
  );
}

export function notifyUnsupportedBrowser(helpUrl = 'http://help.9now.com.au/hc/en-us/articles') {
  notifyError(
    <>
      <span>
        Oops, your web browser is not supported! For the best 9Now experience, please upgrade to a modern
        browser.
      </span>{' '}
      <a
        rel="noopener noreferrer"
        href={`${helpUrl}/115004641807-What-web-browsers-can-I-use-with-9Now-" target="_blank">`}
        target="_blank"
      >
        Learn more.
      </a>
    </>
  );
}

export function notifyGeolocationDenied() {
  notifyWarning(
    <>
      <span>To provide the best experience, 9Now needs access to your location.</span>{' '}
      <a
        rel="noopener noreferrer"
        href="https://help.9now.com.au/hc/en-us/articles/218663187-Why-does-9Now-need-access-to-my-location"
        target="_blank"
      >
        Learn more.
      </a>
    </>,
    { toastId: 'geolocation-denied' }
  );
}

export function notifyLiveStreamsLoadFail() {
  notifyError(
    <span>The schedule for your live stream is temporarily unavailable. Please try again soon.</span>,
    { toastId: 'live-streams-load-fail' }
  );
}

export function notifyLiveStreamEnded() {
  notifyWarning(
    <span>
      The livestream you are watching has ended, you may browse other livestreams for more content.
    </span>,
    { toastId: 'live-stream-ended', autoClose: 8000 }
  );
}

export function notifyAdBlockDetected() {
  notifyError(
    <>
      <span>
        9Now is a free, ad-supported service. Using an ad blocker is likely to affect your video playback
        experience.
      </span>{' '}
      <a
        rel="noopener noreferrer"
        href="http://help.9now.com.au/hc/en-us/articles/360001857536"
        target="_blank"
      >
        Learn more.
      </a>
    </>,
    { toastId: 'ad-block-detected' }
  );
}
