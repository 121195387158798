import { merge } from 'lodash';
import defaultConfig from './default';
import prodConfig from './prod';
import type { Config } from './default';

const config: Config = merge({}, defaultConfig, prodConfig, {
  publicUrl: 'https://beta.9now.com.au',
});

export default config;
