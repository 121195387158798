import { merge } from 'lodash';
import defaultConfig from './default';
import type { Config } from './default';

const config: Config = merge({}, defaultConfig, {
  publicUrl: 'https://staging.9now.com.au',
  apiUrl: 'https://staging.api.9now.com.au',
  tvApiUrl: 'https://staging.tv-api.9now.com.au/v2',
  sso: {
    authUrl: 'https://staging.login.nine.com.au',
    clientId: '9now-web',
    redirectUrl: 'https://staging.9now.com.au/callback',
    settingsUrl: 'https://staging.settings.nine.com.au',
    botAuthorization: process.env.BOT_AUTHORIZATION_STAGING,
  },
  tracking: {
    snowplow: {
      trackingUrl: 'https://t-stage.nineanalytics.io/',
    },
    nuidCookieName: 'staging-NUID',
  },
  livePlayer: {
    accountId: '4460760524001',
    playerId: 'AYrudnsfU',
  },
  mi9tracking: {
    environment: {
      name: 'staging',
      adcUrl: 'staging-adc.9now.com.au',
    },
    nuidCookieName: 'staging-NUID', // set by tracking library
  },
  webDedicatedPlayer: {
    accountId: '4460760524001',
    playerId: 'Fzcg68PKU',
  },
  user: {
    baseUrl: 'https://staging.user-api.9now.com.au/v1',
  },
});

export default config;
